/**
 * A [[TransitionHookFn]] which updates the URL after a successful transition
 *
 * Registered using `transitionService.onSuccess({}, updateUrl);`
 */
var updateUrl = function (transition) {
  var options = transition.options();
  var $state = transition.router.stateService;
  var $urlRouter = transition.router.urlRouter;
  // Dont update the url in these situations:
  // The transition was triggered by a URL sync (options.source === 'url')
  // The user doesn't want the url to update (options.location === false)
  // The destination state, and all parents have no navigable url
  if (options.source !== 'url' && options.location && $state.$current.navigable) {
    var urlOptions = {
      replace: options.location === 'replace'
    };
    $urlRouter.push($state.$current.navigable.url, $state.params, urlOptions);
  }
  $urlRouter.update(true);
};
export var registerUpdateUrl = function (transitionService) {
  return transitionService.onSuccess({}, updateUrl, {
    priority: 9999
  });
};
