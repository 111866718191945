export var resolvePolicies = {
  when: {
    LAZY: 'LAZY',
    EAGER: 'EAGER'
  },
  async: {
    WAIT: 'WAIT',
    NOWAIT: 'NOWAIT'
  }
};
