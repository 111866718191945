import { isArray, isObject } from '../common/index';
/**
 * An angular1-like promise api
 *
 * This object implements four methods similar to the
 * [angular 1 promise api](https://docs.angularjs.org/api/ng/service/$q)
 *
 * UI-Router evolved from an angular 1 library to a framework agnostic library.
 * However, some of the `@uirouter/core` code uses these ng1 style APIs to support ng1 style dependency injection.
 *
 * This API provides native ES6 promise support wrapped as a $q-like API.
 * Internally, UI-Router uses this $q object to perform promise operations.
 * The `angular-ui-router` (ui-router for angular 1) uses the $q API provided by angular.
 *
 * $q-like promise api
 */
export var $q = {
  /** Normalizes a value as a promise */
  when: function (val) {
    return new Promise(function (resolve, reject) {
      return resolve(val);
    });
  },
  /** Normalizes a value as a promise rejection */
  reject: function (val) {
    return new Promise(function (resolve, reject) {
      reject(val);
    });
  },
  /** @returns a deferred object, which has `resolve` and `reject` functions */
  defer: function () {
    var deferred = {};
    deferred.promise = new Promise(function (resolve, reject) {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    return deferred;
  },
  /** Like Promise.all(), but also supports object key/promise notation like $q */
  all: function (promises) {
    if (isArray(promises)) {
      return Promise.all(promises);
    }
    if (isObject(promises)) {
      // Convert promises map to promises array.
      // When each promise resolves, map it to a tuple { key: key, val: val }
      var chain = Object.keys(promises).map(function (key) {
        return promises[key].then(function (val) {
          return {
            key: key,
            val: val
          };
        });
      });
      // Then wait for all promises to resolve, and convert them back to an object
      return $q.all(chain).then(function (values) {
        return values.reduce(function (acc, tuple) {
          acc[tuple.key] = tuple.val;
          return acc;
        }, {});
      });
    }
  }
};
