/**
 * A factory which creates an onEnter, onExit or onRetain transition hook function
 *
 * The returned function invokes the (for instance) state.onEnter hook when the
 * state is being entered.
 */
function makeEnterExitRetainHook(hookName) {
  return function (transition, state) {
    var _state = state.$$state();
    var hookFn = _state[hookName];
    return hookFn(transition, state);
  };
}
/**
 * The [[TransitionStateHookFn]] for onExit
 *
 * When the state is being exited, the state's .onExit function is invoked.
 *
 * Registered using `transitionService.onExit({ exiting: (state) => !!state.onExit }, onExitHook);`
 *
 * See: [[IHookRegistry.onExit]]
 */
var onExitHook = makeEnterExitRetainHook('onExit');
export var registerOnExitHook = function (transitionService) {
  return transitionService.onExit({
    exiting: function (state) {
      return !!state.onExit;
    }
  }, onExitHook);
};
/**
 * The [[TransitionStateHookFn]] for onRetain
 *
 * When the state was already entered, and is not being exited or re-entered, the state's .onRetain function is invoked.
 *
 * Registered using `transitionService.onRetain({ retained: (state) => !!state.onRetain }, onRetainHook);`
 *
 * See: [[IHookRegistry.onRetain]]
 */
var onRetainHook = makeEnterExitRetainHook('onRetain');
export var registerOnRetainHook = function (transitionService) {
  return transitionService.onRetain({
    retained: function (state) {
      return !!state.onRetain;
    }
  }, onRetainHook);
};
/**
 * The [[TransitionStateHookFn]] for onEnter
 *
 * When the state is being entered, the state's .onEnter function is invoked.
 *
 * Registered using `transitionService.onEnter({ entering: (state) => !!state.onEnter }, onEnterHook);`
 *
 * See: [[IHookRegistry.onEnter]]
 */
var onEnterHook = makeEnterExitRetainHook('onEnter');
export var registerOnEnterHook = function (transitionService) {
  return transitionService.onEnter({
    entering: function (state) {
      return !!state.onEnter;
    }
  }, onEnterHook);
};
