var noImpl = function (fnname) {
  return function () {
    throw new Error("No implementation for ".concat(fnname, ". The framework specific code did not implement this method."));
  };
};
export var makeStub = function (service, methods) {
  return methods.reduce(function (acc, key) {
    return acc[key] = noImpl("".concat(service, ".").concat(String(key), "()")), acc;
  }, {});
};
var services = {
  $q: undefined,
  $injector: undefined
};
export { services };
