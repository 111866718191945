import { isString, isFunction } from '../common/predicates';
import { services } from '../common/coreservices';
import { TargetState } from '../state/targetState';
/**
 * A [[TransitionHookFn]] that redirects to a different state or params
 *
 * Registered using `transitionService.onStart({ to: (state) => !!state.redirectTo }, redirectHook);`
 *
 * See [[StateDeclaration.redirectTo]]
 */
var redirectToHook = function (trans) {
  var redirect = trans.to().redirectTo;
  if (!redirect) return;
  var $state = trans.router.stateService;
  function handleResult(result) {
    if (!result) return;
    if (result instanceof TargetState) return result;
    if (isString(result)) return $state.target(result, trans.params(), trans.options());
    if (result['state'] || result['params']) return $state.target(result['state'] || trans.to(), result['params'] || trans.params(), trans.options());
  }
  if (isFunction(redirect)) {
    return services.$q.when(redirect(trans)).then(handleResult);
  }
  return handleResult(redirect);
};
export var registerRedirectToHook = function (transitionService) {
  return transitionService.onStart({
    to: function (state) {
      return !!state.redirectTo;
    }
  }, redirectToHook);
};
