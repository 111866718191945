import { noop } from '../common/common';
import { services } from '../common/coreservices';
/**
 * A [[TransitionHookFn]] which waits for the views to load
 *
 * Registered using `transitionService.onStart({}, loadEnteringViews);`
 *
 * Allows the views to do async work in [[ViewConfig.load]] before the transition continues.
 * In angular 1, this includes loading the templates.
 */
var loadEnteringViews = function (transition) {
  var $q = services.$q;
  var enteringViews = transition.views('entering');
  if (!enteringViews.length) return;
  return $q.all(enteringViews.map(function (view) {
    return $q.when(view.load());
  })).then(noop);
};
export var registerLoadEnteringViews = function (transitionService) {
  return transitionService.onFinish({}, loadEnteringViews);
};
/**
 * A [[TransitionHookFn]] which activates the new views when a transition is successful.
 *
 * Registered using `transitionService.onSuccess({}, activateViews);`
 *
 * After a transition is complete, this hook deactivates the old views from the previous state,
 * and activates the new views from the destination state.
 *
 * See [[ViewService]]
 */
var activateViews = function (transition) {
  var enteringViews = transition.views('entering');
  var exitingViews = transition.views('exiting');
  if (!enteringViews.length && !exitingViews.length) return;
  var $view = transition.router.viewService;
  exitingViews.forEach(function (vc) {
    return $view.deactivateViewConfig(vc);
  });
  enteringViews.forEach(function (vc) {
    return $view.activateViewConfig(vc);
  });
  $view.sync();
};
export var registerActivateViews = function (transitionService) {
  return transitionService.onSuccess({}, activateViews);
};
